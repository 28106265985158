import React, { useEffect, useMemo } from 'react';
import { View, StyleSheet, Linking, Text } from 'react-native';
import { applyTTStyles, COLORS } from '../../constants/colors';
import DeviceInfo from 'react-native-device-info';
import { envs } from '../../../env';
import { textStyles } from '../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { useCurrentCompanyContext } from '../../context/CurrentCompanyContext';
import { utils } from '../../utils/utils';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

export function extractPrefixAndPath(urlString: string) {
    // Parse the URL using the URL API
    const url = new URL(urlString);

    // Construct the path by combining pathname and search
    const pathAndQuery = url.pathname.replace(/^\//, '') + url.search;

    return {
        // prefix: url.host.split('temptrack.mightybyte.us')[0].replace(/\./g, ''),
        prefix: url.host.split(envs.BASE_URL)[0].replace(/\./g, ''),
        pathAndQuery: pathAndQuery,
    };
}
async function openDeepLink() {
    if (isMobileApp) {
        return;
    }
    const { pathAndQuery, prefix } = extractPrefixAndPath(
        MB_customWindowForWeb.location.href,
    );
    const postfix = pathAndQuery?.includes('?')
        ? `${pathAndQuery}&domain=${prefix}`
        : `?domain=${prefix}`;
    const redirectUrl = `${envs.MOBILE_DEEP_LINK}${postfix}`;
    const baseOS = await DeviceInfo.getBaseOs();
    if (baseOS === 'Android') {
        Linking.openURL(redirectUrl);
    } else {
        openAppStore();
    }
}

async function openAppStore() {
    const baseOS = await DeviceInfo.getBaseOs();
    if (baseOS === 'Android') {
        Linking.openURL(envs.PLAY_STORE_DEEP_LINK);
    } else {
        Linking.openURL(envs.APP_STORE_DEEP_LINK);
    }
}

const InstallApp = () => {
    const { currentCustomDomain } = useCurrentCompanyContext();

    const isAppInstalled = async () => {
        if (isMobileApp) {
            return true;
        }
        let isInstalled = false;
        try {
            isInstalled = await Linking.canOpenURL(envs.MOBILE_DEEP_LINK);
        } catch (e) {
            console.log('Error checking if app is installed', e);
        }
        return isInstalled;
    };

    const shouldInstallFirst = useMemo(() => {
        return !isAppInstalled();
    }, []);

    useEffect(() => {
        if (isMobileApp) {
            return;
        }

        if (shouldInstallFirst) {
            openAppStore();
        } else {
            openDeepLink();
        }
    }, [shouldInstallFirst]);

    return (
        <View style={styles.container}>
            <Text style={styles.textLarge}>Here is the mobile app!</Text>
            <Text style={styles.textSmall}>
                The current version of this website not supported in mobile
                browser please click below button to download the mobile app
            </Text>
            <MB_Button
                title={shouldInstallFirst ? 'Install App' : 'Open App'}
                style={{
                    backgroundColor: applyTTStyles(
                        utils.isTTEmployee(currentCustomDomain),
                    ).primaryButtonBg,
                }}
                onPress={shouldInstallFirst ? openAppStore : openDeepLink}
            />
        </View>
    );
};

export { InstallApp };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        padding: 32,
        justifyContent: 'center',
    },
    textLarge: mbTextStyles([
        textStyles.Muli_16_600,
        {
            color: COLORS.textPrimary,
            fontSize: 20,
        },
    ]),
    textSmall: mbTextStyles([
        textStyles.Muli_16_400,
        {
            color: COLORS.textSecondary,
            marginTop: 12,
            marginBottom: 32,
        },
    ]),
});
